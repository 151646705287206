import * as React from "react"

const Linkedin = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="55" viewBox="0 0 52 55" fill="none">
	<defs>
    <style>{"#layer1:hover .fg{stroke: rgb(123, 44, 191);fill: rgb(123, 44, 191);stroke-width: 14px;} #layer1:hover .bg{stroke: white;fill: rgb(123, 44, 191);}"}</style>
    <style>{"rect {fill: transparent;stroke: rgb(123, 44, 191);stroke-width: 4;fill-opacity: 1;stroke-opacity: 1;}"}</style>
  </defs>
  <g id="layer1">
    	
    <path xmlns="http://www.w3.org/2000/svg" class="fg" d="M15.7143 22.0357V40.25M36.2857 40.25V31.1429C36.2857 27.1191 33.2157 23.8571 29.4286 23.8571C25.6415 23.8571 22.5714 27.1191 22.5714 31.1429V40.25V22.0357M14 16.5714H17.4286M5.42857   " stroke="#7B2CBF" stroke-width="4"/>
    <path xmlns="http://www.w3.org/2000/svg" class="bg" d="M15.7143 22.0357V40.25M36.2857 40.25V31.1429C36.2857 27.1191 33.2157 23.8571 29.4286 23.8571C25.6415 23.8571 22.5714 27.1191 22.5714 31.1429V40.25V22.0357M14 16.5714H17.4286M5.42857 2H46.5714C48.465 2 50 3.63096 50 5.64286V49.3571C50 51.369 48.465 53 46.5714 53H5.42857C3.53503 53 2 51.369 2 49.3571V5.64286C2 3.63096 3.53502 2 5.42857 2Z" stroke="#7B2CBF" stroke-width="4"/>
    <rect xmlns="http://www.w3.org/2000/svg" x="2" y="2" rx="4" width="48" height="51" />
  </g>
</svg>
)

export default Linkedin
