// extracted by mini-css-extract-plugin
export var teamMainWrapper = "team-module--teamMainWrapper--JF91v";
export var heading3 = "team-module--heading3--BqU6t";
export var teamContainer = "team-module--teamContainer--8j3-i";
export var teamTopSection = "team-module--teamTopSection--+Dqmc";
export var teamTopSectionRight = "team-module--teamTopSectionRight--o8RZv";
export var teamPageTitle = "team-module--teamPageTitle--U3R41";
export var teamMetaTitle = "team-module--teamMetaTitle--7v04s";
export var teamMetaDescription = "team-module--teamMetaDescription--Q1yDq";
export var teamBreadcrumbs = "team-module--teamBreadcrumbs--OmEUd";
export var teamMainContentWrapper = "team-module--teamMainContentWrapper--6bu6V";
export var teamDoubleCard = "team-module--teamDoubleCard--e5Ms-";
export var first = "team-module--first--4t5q4";
export var ourBoardBlocks = "team-module--ourBoardBlocks--y0ll6";
export var ourBoardBlock = "team-module--ourBoardBlock--Lcppl";
export var heading = "team-module--heading--SREQ5";