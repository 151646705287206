import React from 'react';
import Layout from '../components/organisms/Layout/Layout';
import Seo from "../components/organisms/Seo/Seo";
import Container from '../components/atoms/Container/Container';
import * as styles from './team.module.css';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import { graphql } from 'gatsby';
import PageBannerPolicy from "../components/atoms/PageBannerPolicy/PageBannerPolicy";
import PageLayout from "../components/atoms/Contentful/ContentfulPageLayout";
import Linkedin from '../assets/svg/TempIcons/linkedin';

const TeamContentful = ({ data }) => {
    const page = (data && data.teamPage) || false;
    const contentfulPage = (page.blocks && page.blocks[0]) || false;
    const contentfulSections = (contentfulPage && contentfulPage.blocks) || false;
    const bottomSection = (page.blocks && page.blocks[1]) || false;
    const bottomContent = (bottomSection && bottomSection.blocks) || false;
    return (
        <Layout genClass={styles.teamMainWrapper}>
                <Seo title="Team" />
                <PageBannerPolicy
                  title={page.pageTitle}
                  description={page.metaTitle}
                />
                {page && (
                  
                    <Container size="medium" >
                    <div className={styles.teamContainer}>
                    <Breadcrumbs crumbs={[{link: '/', label: 'Home'}, {label: 'Team'}]}/>
                    <h3 className={styles.heading3} style={{marginTop: 0}}>
                      {page.heading}
                    </h3>
                    </div>
                  </Container>
                )}
            {page && (
                <>
                    <Container size={'medium'}>
                    <div className={styles.teamContainer}>
                    <div className={styles.teamDoubleCard}>
                      <div data-components
                          data-index={0}
                          data-alignment={''}
                          data-text-color={'261A36'}
                          style={{width: 'full-width'}}
                          data-last={false}
                      >
                        <PageLayout
                          pageName={page.pageName}
                          section={contentfulSections[0]}
                          sectionIndex={0}
                        />
                        <PageLayout
                          pageName={page.pageName}
                          section={contentfulSections[1]}
                          sectionIndex={1}
                        />
                        <PageLayout
                          pageName={page.pageName}
                          section={contentfulSections[2]}
                          sectionIndex={2}
                        />
                         <PageLayout
                          pageName={page.pageName}
                          section={contentfulSections[3]}
                          sectionIndex={3}
                        />
                         <PageLayout
                          pageName={page.pageName}
                          section={contentfulSections[4]}
                          sectionIndex={4}
                        />
                         <PageLayout
                          pageName={page.pageName}
                          section={contentfulSections[5]}
                          sectionIndex={5}
                        />
                         <PageLayout
                          pageName={page.pageName}
                          section={contentfulSections[6]}
                          sectionIndex={6}
                        />
                      </div>
                    </div>
                    </div>
                  </Container>
                <section data-section="ourBoard">
                  <Container size={'medium'}>
                    <h3>Our Board of Directors</h3>
                    <div className={styles.ourBoardBlocks}>
                      {
                        bottomContent.map((block) => {
                          return( 
                            <div className={styles.ourBoardBlock}>
                              <a href={block.buttonUrl} target="_blank" rel="noreferrer noopener">
                              <div className={styles.heading}>
                              <h4>{block.name}</h4>
                              <Linkedin width={135} height={125} />
                              </div>
                                </a>
                              <div dangerouslySetInnerHTML={{
                              __html: block.content.childMarkdownRemark.html
                                    }}></div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </Container>
                </section>
                    
                </>
            )}
        </Layout>
    );
}

export default TeamContentful;

export const query = graphql`
query ContentfulPageTeam {
  teamPage: contentfulPage(pageName: {eq: "Team New"}) {
    pageName
    pageTitle
    metaCanonicalLink
    metaDescription
    metaTitle
    breadcrumbs
    logoStyle {
      logoStyle
    }
    blocks {
      blocks {
        ... on ContentfulBlockDoubleCards {
          name
          internal {
            type
          }
          title
          backgroundColour
          textColour {
            textColour {
              textColour
            }
          }
          backgroundImage {
            altText
            imageMaxWidth
            desktopImage {
              gatsbyImageData
              file {
                url
              }
            }
            mobileImage {
              gatsbyImageData
              file {
                url
              }
            }
          }
          blocks {
            alignment {
              alignment
            }
            blockWidth {
              blockWidth
            }
            backgroundColour
          }
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          paddingTopBottom {
            paddingTopBottom {
              paddingTopBottom
            }
          }
          blockAlignment {
            blockAlignment
          }
          mobileRevert
          contentLeft {
            heading
            headingColor
            childContentfulBlockContentContentTextNode {
              childMarkdownRemark {
                html
              }
            }
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            name
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockAlignment {
              blockAlignment
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            headingSize {
              headingSize
            }
            textAlignment {
              textAlignment
            }
            textColour
            imagePosition {
              imagePosition
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            backgroundColour
          }
          contentRight {
            heading
            childContentfulBlockContentContentTextNode {
              childMarkdownRemark {
                html
              }
            }
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            name
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockAlignment {
              blockAlignment
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            headingSize {
              headingSize
            }
            textAlignment {
              textAlignment
            }
            textColour
            imagePosition {
              imagePosition
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            backgroundColour
          }
        }
        ... on ContentfulBlockContent {
          name
          backgroundColour
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          blockAlignment {
            blockAlignment
          }
          buttonName
          buttonUrl
          buttonStyle {
            buttonStyle
          }
          heading
          headingColor
          headingSize {
            headingSize
          }
          imagePosition {
            imagePosition
          }
          imageHoverAnimation {
            imageHoverAnimation
          }
          backgroundImage {
            altText
            imageMaxWidth
            desktopImage {
              gatsbyImageData
              file {
                url
              }
            }
            mobileImage {
              gatsbyImageData
              file {
                url
              }
            }
          }
          textAlignment {
            textAlignment
          }
          imagePosition {
            imagePosition
          }
          internal {
            type
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
}

`